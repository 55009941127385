import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './StartScreen.css';
import coinImage from '../images/coin.png';

const StartScreen = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [clicks, setClicks] = useState(0);
  const [showTutorial, setShowTutorial] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  const [popUps, setPopUps] = useState([]);
  const [tutorialStarted, setTutorialStarted] = useState(false);
  const [timer, setTimer] = useState(0);

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'ar', name: 'العربية' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fa', name: 'فارسی' },
    { code: 'fr', name: 'Français' },
    { code: 'tr', name: 'Türkçe' },
  ];

  useEffect(() => {
    let interval;
    if (tutorialStarted) {
      interval = setInterval(() => {
        setTimer(prevTimer => prevTimer + 1);
      }, 1000);
    }

    if (timer >= 300) { // 5 minutes
      navigate('/character-creation');
    }

    return () => clearInterval(interval);
  }, [tutorialStarted, timer, navigate]);

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const getPopUpText = (clicks) => {
    if (clicks < 25) {
      return { text: t('startScreen.popUps.more'), color: "lightgreen" };
    } else if (clicks < 40) {
      return { text: t('startScreen.popUps.yesMore'), color: "green" };
    } else if (clicks < 50) {
      return { text: t('startScreen.popUps.almostThere'), color: "darkgreen" };
    } else if (clicks < 60) {
      return { text: t('startScreen.popUps.stop'), color: "red" };
    } else {
      return { text: t('startScreen.popUps.embarrassing'), color: "darkred" };
    }
  };

  const handleCoinClick = (event) => {
    setIsAnimating(true);
    setTimeout(() => setIsAnimating(false), 100);

    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    const newPopUp = { x, y, id: Date.now(), ...getPopUpText(clicks) };
    setPopUps(prevPopUps => [...prevPopUps, newPopUp]);

    setClicks(prevClicks => {
      const newClicks = prevClicks + 1;
      if (newClicks === 50) {
        setShowTutorial(true);
      }
      return newClicks;
    });

    setTimeout(() => {
      setPopUps(prevPopUps => prevPopUps.filter(popUp => popUp.id !== newPopUp.id));
    }, 1000);
  };

  const handleWatchTutorial = () => {
    setTutorialStarted(true);
    window.open("https://youtu.be/kZGUdrV8SUs", "_blank");
  };

  const handleSkipTutorial = () => {
    if (window.confirm(t('startScreen.skipTutorialWarning'))) {
      navigate('/character-creation');
    }
  };

  const getTutorialText = () => {
    if (!tutorialStarted) {
      return t('startScreen.tutorialText.beforeTutorial');
    } else if (timer > 0 && timer < 300) {
      return t('startScreen.tutorialText.duringTutorial');
    } else {
      return t('startScreen.tutorialText.afterTutorial');
    }
  };

  return (
    <div className="start-screen">
      <div className="start-screen__language-container">
        <div className="start-screen__language-selector">
          {languages.map((lang) => (
            <button 
              key={lang.code}
              className={`start-screen__language-button ${i18n.language === lang.code ? 'start-screen__language-button--active' : ''}`} 
              onClick={() => handleLanguageChange(lang.code)}
            >
              {lang.name}
            </button>
          ))}
        </div>
      </div>
      <h1 className="start-screen__title">{t('startScreen.welcome')}</h1>
      <h2 className="start-screen__subtitle">{t('startScreen.clickInstructions')}</h2>
      <div className="start-screen__coin-container" onClick={handleCoinClick}>
        <img 
          src={coinImage} 
          alt={t('startScreen.coinAlt')} 
          className={`start-screen__coin ${isAnimating ? 'start-screen__coin--animate' : ''}`} 
        />
        <p>{t('startScreen.clickCount', { clicks, total: 50 })}</p>
        {popUps.map(popUp => (
          <div 
            key={popUp.id} 
            className="start-screen__pop-up-text" 
            style={{ left: popUp.x, top: popUp.y, color: popUp.color }}
          >
            {popUp.text}
          </div>
        ))}
      </div>
      {showTutorial && (
        <>
          <div className="start-screen__tutorial-text">
            {getTutorialText()}
          </div>
          {!tutorialStarted && (
            <div className="start-screen__tutorial-buttons">
              <button 
                onClick={handleWatchTutorial} 
                className="start-screen__tutorial-button start-screen__tutorial-button--primary"
              >
                {t('startScreen.watchTutorial')}
              </button>
              <button 
                onClick={handleSkipTutorial} 
                className="start-screen__tutorial-button start-screen__tutorial-button--secondary"
              >
                {t('startScreen.skipTutorial')}
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default StartScreen;