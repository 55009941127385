  // src/components/MainGameScreen.js
  import React, { useEffect, useState,useRef } from 'react';
  import { useSelector, useDispatch } from 'react-redux';
  import {
    addCoins,
    addExperience,
    increaseWorkBonus,
    addKnowledge,
    addPower,
    addCombatTrainingHours,
    setPendingMessages,
    levelUp,
    addItem,
    updateLastLogin,
    checkAndAwardDailyReward,
    clearDailyRewardMessage,
    addIdleCoins,
    spendCoins,
    resetGame,
    updateCharacter,
    addPendingMessage,
    setTelegramId ,
    setReferralCode ,
    removePendingMessage,
    incrementCombatTrainingSessions,
    setCurrentSkin,
    spendItem
  } from '../redux/playerSlice';
  import { setHasNewAchievements } from '../redux/gameSlice';
  import { useTranslation } from 'react-i18next'; // Import useTranslation hook
  import TimerDisplay from './TimerDisplay';
  import Modal from './Modal';
  import MapExploration from './MapExploration';
  import { regions, rareItems } from './MapExploration';
  import WeaponLvl3SmallSword from './WeaponLvl3SmallSword';
  import WeaponLvl2Arrow from './WeaponLvl2Arrow'; 
  import LegendWeaponsLvl4 from './LegendWeaponsLvl4';
  import { loadPersistedState } from '../redux/playerSlice';
  import { useTelegramWebApp } from '../contexts/TelegramWebAppContext';
  import Merchant from './Merchant';
  import Market from './Market';
  import ReferralSystem from './ReferralSystem';
  import SocialTasks from './SocialTasks';
  import Achievements from './Achievements';
  import SkillSelection from './SkillSelection';
  import LoadingScreen from './LoadingScreen';
  import SettingsModal from './SettingsModal';
  import WalletModal from './WalletModal';
  import { getExplorationStory } from '../utils/explorationStories';
  import InventoryModal from './InventoryModal'; // Import InventoryModal
  import MiniGames from './minigames/index'; 
  import CustomAlert from './CustomAlert';
  import './MainGameScreen.css';
  import WebApp from '@twa-dev/sdk';

  // Import the background images
  import workImage from '../images/buttons/work.jpg';
  import illuminationImage  from '../images/buttons/illuminationImage.jpg';
  import combatTrainingImage from '../images/buttons/combat-training.jpg';
  import mapExplorationImage from '../images/buttons/mapExploration.jpg';
  import merchantImage from '../images/buttons/merchantImage.jpg';
  import WeaponLvl2ArrowImage from '../images/buttons/WeaponLvl2Arrow.jpg';
  import WeaponLvl3SmallSwordImage from '../images/buttons/WeaponLvl3SmallSword.jpg';
  import LegendWeaponsLvl4Image from '../images/buttons/LegendWeaponsLvl4.jpg';
  import loadingBackground from '../images/backgrounds/load.jpg';
  import coinImage from '../images/coin.png';
  import experienceIcon from '../images/experienceIcon.png';
  import knowledgeIcon from '../images/knowledgeIcon.png';
  import powerIcon from '../images/powerIcon.png';
  import coinIcon from '../images/coin.png';


  // Import tab icons
  import mainIcon from '../images/icons/main.png';
  import marketIcon from '../images/icons/market.png';
  import referralIcon from '../images/icons/referral.png';
  import socialTasksIcon from '../images/icons/social-tasks.png';
  import achievementsIcon from '../images/icons/achievements.png';
  import walletIcon from '../images/icons/wallet.png';
  import settingsIcon from '../images/icons/gear.png';
  // Import character images
  import level1Male from '../images/characters/male/level1.png';
  import level2Male from '../images/characters/male/level2.png';
  import level3Male from '../images/characters/male/level3.png';
  import level4Male from '../images/characters/male/level4.png';
  import level1Female from '../images/characters/female/level1.png';
  import level2Female from '../images/characters/female/level2.png';
  import level3Female from '../images/characters/female/level3.png';
  import level4Female from '../images/characters/female/level4.png';
  // Import skin images
  import PharaohWarrior from '../images/characters/male/skins/PharaohWarrior.png';
  import MummyGuardian from '../images/characters/male/skins/MummyGuardian.png';
  import AnubisFighter from '../images/characters/male/skins/AnubisFighter.png';
  import RasChosen from '../images/characters/male/skins/RasChosen.png';
  import SphinxSentinel from '../images/characters/male/skins/SphinxSentinel.png';
  import OsirisReborn from '../images/characters/male/skins/OsirisReborn.png';
  import ScarabKnight from '../images/characters/male/skins/ScarabKnight.png';
  import SetsEnforcer from '../images/characters/male/skins/SetsEnforcer.png';
  // Import skin images
  import DesertNomadlvl3 from '../images/characters/female/skins/DesertNomadlvl3.png';
  import DesertNomadlvl4 from '../images/characters/female/skins/DesertNomadlvl4.png';
  import EmperorWarriorlvl3 from '../images/characters/female/skins/Emperor Warriorlvl3.png';
  import EmperorWarriorlvl4 from '../images/characters/female/skins/Emperor Warriorlvl4.png';
  import Jade from '../images/characters/female/skins/Jade.png';
  import PharaohsGuardlvl4 from '../images/characters/female/skins/Pharaoh\'sGuardlvl4.png';
  import PracticalWarriorlvl3 from '../images/characters/female/skins/PracticalWarriorlvl3.png';
  import PracticalWarriorlvl4 from '../images/characters/female/skins/PracticalWarriorlvl4.png';
  
  import mythosSound from '../sounds/mythos.mp3';
  import level3Sound from '../sounds/level3.mp3';
  import level4Sound from '../sounds/level4.mp3';

  const MainGameScreen = () => {
    const dispatch = useDispatch();
    const player = useSelector((state) => state.player);
    const [isLoading, setIsLoading] = useState(true); // Define loading state
    const [loadingProgress, setLoadingProgress] = useState(0)
    const [activityTimer, setActivityTimer] = useState(0);
    const [activeTab, setActiveTab] = useState(null);
    const dailyRewardMessage = useSelector(state => state.player.dailyRewardMessage);
    const [showRewardAlert, setShowRewardAlert] = useState(false);
    const [isMuted, setIsMuted] = useState(false);
    const audioRefs = useRef({
      default: new Audio(mythosSound),
      level3: new Audio(level3Sound),
      level4: new Audio(level4Sound)
    });
    const [currentActivity, setCurrentActivity] = useState(null);
    const [alertMessage, setAlertMessage] = useState(null);
    const [explorationRewards, setExplorationRewards] = useState(null);
    const [equippedSkin, setEquippedSkin] = useState(null);
    const [activityCompleted, setActivityCompleted] = useState(false);
    const [completionMessageShown, setCompletionMessageShown] = useState(false);
    const [isMerchantAvailable, setIsMerchantAvailable] = useState(false);
    const [nextMerchantVisit, setNextMerchantVisit] = useState(null);
    const hasNewAchievements = useSelector(state => state.game.hasNewAchievements);
    const webApp = useTelegramWebApp();
    const [showSkillSelection, setShowSkillSelection] = useState(false);
    const { t } = useTranslation();
    const [currentSkin, setLocalCurrentSkin] = useState(player.currentSkin || 'default');
    const [isWorking, setIsWorking] = useState(false);
    const [workTimer, setWorkTimer] = useState(0);
    const pendingMessages = useSelector((state) => state.player.pendingMessages);
    const [currentMessage, setCurrentMessage] = useState(null);
    const [merchantCountdown, setMerchantCountdown] = useState(null);
    const [showMiniGames, setShowMiniGames] = useState(false);
    const [modalStates, setModalStates] = useState({
      market: false,
      referral: false,
      socialTasks: false,
      achievements: false,
      skillSelection: false,
      mapExploration: false,
      WeaponLvl2Arrow: false,
      WeaponLvl3SmallSword: false,
      LegendWeaponsLvl4: false,
      settings: false,
      wallet: false,
      merchant: false,
      inventory: false, // Add inventory modal state
    });
    const imagesToLoad = [
      workImage,
      illuminationImage,
      combatTrainingImage,
      mapExplorationImage,
      merchantImage,
      WeaponLvl2ArrowImage,
      WeaponLvl3SmallSwordImage,
      LegendWeaponsLvl4Image,
      socialTasksIcon,achievementsIcon,walletIcon,settingsIcon,marketIcon, referralIcon, mainIcon,
      coinIcon, powerIcon, knowledgeIcon,  experienceIcon,
      // ... add other image paths here
    ];


    const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    };
    const getGlowClass = () => {
      const baseClass = player.gender === 'male' ? 'glow-blue' : 'glow-pink';
      const levelClass = `level-${Math.min(player.level, 4)}`; // Capped at level 4
      return `${baseClass} ${levelClass}`;
    };

const telegramId = WebApp.initDataUnsafe?.user?.id || 0;

async function sendPlayerDataToCloudflare() {
  const playerData = {
    telegramId,
    coins: player.coins,
    experience: player.experience,
    power: player.power,
    knowledge: player.knowledge,
    level: player.level,
    name: player.name,
    login_time: new Date().toISOString(), // Capture the current time in ISO format
  };

  try {
    const response = await fetch('https://stardb.game2mythos.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(playerData),
    });

    if (!response.ok) {
      console.error('Failed to send player data:', await response.text());
    }
  } catch (error) {
    console.error('Error sending player data:', error);
  }
}

// Send player data when the daily reward message shows up
if (typeof dailyRewardMessage !== 'undefined' && dailyRewardMessage) {
  sendPlayerDataToCloudflare();
}




    useEffect(() => {
      let loadedImages = 0;

      const preloadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = () => {
            loadedImages++;
            setLoadingProgress(Math.floor((loadedImages / imagesToLoad.length) * 100));
            resolve();
          };
          img.onerror = reject;
        });
      };

      Promise.all(imagesToLoad.map(preloadImage))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error('Error loading images:', error);
          setIsLoading(false); // Optionally set to false even if there's an error
        });
    }, []);

    useEffect(() => {
      // Check and award daily reward when the component mounts
      dispatch(checkAndAwardDailyReward());
    }, [dispatch]);
  

    useEffect(() => {
      if (dailyRewardMessage) {
        setShowRewardAlert(true);
      }
    }, [dailyRewardMessage]);
  
    const handleCloseAlert = () => {
      setShowRewardAlert(false);
      dispatch(clearDailyRewardMessage());
    };

    useEffect(() => {
      if (pendingMessages && pendingMessages.length > 0 && !currentMessage) {
        setCurrentMessage(pendingMessages[0]);
      }
    }, [pendingMessages, currentMessage]);
  
    const handleMessageClose = () => {
      dispatch(removePendingMessage(0));
      setCurrentMessage(null);
    };

    useEffect(() => {
      // Check for merchant availability every minute
      const interval = setInterval(checkMerchantAvailability, 60000);
      return () => clearInterval(interval);
    }, []);
  
    const checkMerchantAvailability = () => {
      const now = new Date();
      const day = now.getDay();
      const hour = now.getHours();
  
      // Merchant appears every Wednesday (3) at 3 PM
      if (day === 3 && hour === 15) {
        setIsMerchantAvailable(true);
        setNextMerchantVisit(null);
        setMerchantCountdown(null);
      } else {
        setIsMerchantAvailable(false);
        
        // Calculate next visit
        const nextWednesday = new Date();
        nextWednesday.setDate(nextWednesday.getDate() + ((3 + 7 - nextWednesday.getDay()) % 7));
        nextWednesday.setHours(15, 0, 0, 0);
        setNextMerchantVisit(nextWednesday);
  
        // Start countdown
        const countdownInterval = setInterval(() => {
          const now = new Date();
          const timeDiff = nextWednesday - now;
          if (timeDiff > 0) {
            const days = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((timeDiff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((timeDiff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((timeDiff % (1000 * 60)) / 1000);
            setMerchantCountdown({ days, hours, minutes, seconds });
          } else {
            clearInterval(countdownInterval);
            setMerchantCountdown(null);
            checkMerchantAvailability(); // Recheck availability
          }
        }, 1000);
  
        return () => clearInterval(countdownInterval);
      }
    };
    
    useEffect(() => {
      if (player.pendingSkillSelection) {
        setShowSkillSelection(true);
      }
    }, [player.pendingSkillSelection]);
    useEffect(() => {
      const gameState = localStorage.getItem('gameState');
      if (gameState) {
        const parsedState = JSON.parse(gameState);
        dispatch(loadPersistedState(parsedState.player));
        // You might need to do this for other slices as well
      }
    }, [dispatch]);
    

    useEffect(() => {
      const checkCompletedActivities = async () => {
        const savedTimerEnd = localStorage.getItem('timerEndTime');
        const savedActivity = localStorage.getItem('currentActivity');
        
        if (savedTimerEnd && savedActivity) {
          try {
            // Fetch current time from the server
            const response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC'); // Or use your own server
            const data = await response.json();
            const now = new Date(data.utc_datetime).getTime();
            
            const endTime = parseInt(savedTimerEnd, 10);
            if (now >= endTime) {
              handleActivityCompletion(savedActivity);
              localStorage.removeItem('timerEndTime');
              localStorage.removeItem('currentActivity');
            } else {
              setActivityTimer(endTime - now);
              setCurrentActivity(savedActivity);
            }
          } catch (error) {
            console.error('Failed to fetch server time:', error);
          }
        }
      };
      
      checkCompletedActivities();
    }, []);
    
    


    const handleTabClick = (tab) => {
      setActiveTab(tab);
      if (tab === 'achievements') {
        dispatch(setHasNewAchievements(false));
      }
    };
    
    useEffect(() => {
      // Load saved timer state on component mount
      const savedTimerEnd = localStorage.getItem('timerEndTime');
      const savedActivity = localStorage.getItem('currentActivity');
      
      if (savedTimerEnd && savedActivity) {
        const now = Date.now();
        const endTime = parseInt(savedTimerEnd, 10);
        if (endTime > now) {
          setActivityTimer(endTime - now);
          setCurrentActivity(savedActivity);
        } else {
          // Timer has expired, clear saved data
          localStorage.removeItem('timerEndTime');
          localStorage.removeItem('currentActivity');
        }
      }
    }, []);
    
    const handleMainTabClick = () => {
      setActiveTab(null);
    };

    const [showProfile, setShowProfile] = useState(false);

    useEffect(() => {
      const timer = setInterval(() => {
        setLoadingProgress(prev => {
          if (prev >= 100) {
            setIsLoading(false);
            clearInterval(timer);
            return 100;
          }
          return prev + 10;
        });
      }, 500);
      return () => clearInterval(timer);
    }, []);

    useEffect(() => {
      let interval;
      if (currentActivity && activityTimer > 0) {
        interval = setInterval(() => {
          setActivityTimer(prevTimer => {
            if (prevTimer <= 1000) {
              clearInterval(interval);
              setActivityCompleted(true);
              return 0;
            }
            return prevTimer - 1000;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    }, [currentActivity, activityTimer]);

    const handleExplorationStart = (region, rewards, fullActivityName) => {
      // Use the untranslated region name in the activity name
      const activityName = `Explore ${region.name}`;
      startActivity(activityName, region.duration * 60 * 1000);
      setExplorationRewards(rewards);
    };

    useEffect(() => {
      if (activityCompleted && currentActivity) {
        handleActivityCompletion(currentActivity);
        setActivityCompleted(false);
        setCurrentActivity(null);
        setActivityTimer(0);
        localStorage.removeItem('timerEndTime');
        localStorage.removeItem('currentActivity');
      }
    }, [activityCompleted, currentActivity]);


    const checkLevelUp = () => {
      dispatch(levelUp());
    };


    const handleActivityCompletion = (activity) => {
      console.log("Activity completion initiated:", activity);
    
      const explorePrefix = "Explore ";
      const knowledgeMultiplier = 1 + Math.floor(player.knowledge / 10) * 0.1;
    
      if (activity.startsWith(explorePrefix)) {
        console.log("Exploration activity detected");
        
        const regionName = activity.slice(explorePrefix.length).trim();
        console.log("Region name:", regionName);
    
        const region = regions.find(r => r.name === regionName);
        console.log("Found region object:", region);
        
        if (region && explorationRewards) {
          console.log("Valid region and rewards detected.");
          console.log("Exploration rewards:", explorationRewards);
      
          const specialScenarios = {
            rareItem: explorationRewards.items.find(item => rareItems.includes(item.id))?.id,
            hunting: regionName === 'Mountain' && player.items['Bow'] > 0 && (player.items['Arrow'] || 0) >= 5,
            blacksmithItems: regionName === 'River Valley' && player.items['BlacksmithTrust'] >= 1 && !player.items['BlacksmithBelongings']
          };
          console.log("Special scenarios:", specialScenarios);
      
          const randomItem = explorationRewards.items[Math.floor(Math.random() * explorationRewards.items.length)];
          const updatedRewards = {
            ...explorationRewards,
            items: [randomItem]
          };
          console.log("Updated rewards with random item:", updatedRewards);
      
          let story;
          try {
            story = getExplorationStory(regionName, updatedRewards, specialScenarios);
            console.log("Story returned by getExplorationStory:", story);
          } catch (error) {
            console.error("Error generating exploration story:", error);
            story = t('exploration.error');
          }
      
          if (story && story.trim() && story !== 'exploration.error') {
            console.log("Dispatching generated story to pending messages.");
            dispatch(addPendingMessage(story));
          } else {
            console.log("Invalid story generated, using default message.");
            dispatch(addPendingMessage(t('exploration.default', {
              region: regionName, 
              coins: updatedRewards.coins, 
              items: updatedRewards.items.map(item => `${item.quantity} ${item.id}`).join(', ')
            })));
          }
          
          console.log("Applying rewards.");
          dispatch(addCoins(explorationRewards.coins));
          dispatch(addItem(randomItem));
          
          if (specialScenarios.hunting) {
            console.log("Applying hunting scenario rewards.");
            dispatch(spendItem({ id: 'Arrow', quantity: 5 }));
            dispatch(addItem({ id: 'Leather', quantity: 15 }));
          }
          
          if (specialScenarios.blacksmithItems) {
            console.log("Applying blacksmith scenario rewards.");
            dispatch(addItem({ id: 'BlacksmithBelongings', quantity: 1 }));
          }
          
          setExplorationRewards(null);
        } else {
          console.log("Region or exploration rewards not found.");
          dispatch(addPendingMessage(t('exploration.error')));
        }
      } else {

      const actions = {
        [t('activities.work')]: () => {
          const baseCoins = 50;
          const scaledCoins = Math.floor(baseCoins * knowledgeMultiplier * (player.coinMultiplier || 1));
          dispatch(addCoins(scaledCoins));
          dispatch(addExperience(5));
          dispatch(addPendingMessage(t('activities.workCompleted', { coins: scaledCoins, experience: 5 })));
          checkLevelUp();
        },
        [t('activities.illumination')]: () => {
          dispatch(increaseWorkBonus(0.01));
          dispatch(addKnowledge(5));
          dispatch(addPendingMessage(t('activities.illuminationCompleted')));
          checkLevelUp();
        },
        [t('activities.combatTraining')]: () => {
          const maxSessions = player.level >= 2 ? 2 : 1;
          
          if (player.combatTrainingSessionsToday < maxSessions) {
            const basePower = 5;
            const scaledPower = Math.floor(basePower * knowledgeMultiplier);
            dispatch(addPower(scaledPower));
            dispatch(levelUp());
            dispatch(incrementCombatTrainingSessions());
            
            const finalPower = player.activeBoosts['Combat Training Booster'] && 
                               player.activeBoosts['Combat Training Booster'] > Date.now() 
                               ? Math.floor(scaledPower * 1.75) 
                               : scaledPower;
            dispatch(addPendingMessage(t('activities.combatTrainingCompleted', { power: finalPower })));
            checkLevelUp();
          } else {
           
          }
        },
        'crafting weapons': handleCraftingClick,
        CraftBowFrame: () => {
          dispatch(addItem({ id: 'BowFrame', quantity: 1 }));
          dispatch(addPendingMessage('Bow Frame crafted successfully!'));
        },
        CraftBow: () => {
          dispatch(addItem({ id: 'Bow', quantity: 1 }));
          dispatch(addPendingMessage('Bow crafted successfully!'));
        },
        CraftArrow: () => {
          dispatch(addItem({ id: 'Arrow', quantity: 10 }));
          dispatch(addPendingMessage('10 Arrows crafted successfully!'));
        },
        CraftBlacksmithTrust: () => {
          dispatch(addItem({ id: 'BlacksmithTrust', quantity: 1 }));
          dispatch(addPendingMessage('You\'ve gained the Blacksmith\'s Trust!'));
        },
        CraftBlacksmithBelongings: () => {
          dispatch(addItem({ id: 'BlacksmithBelongings', quantity: 1 }));
          dispatch(addPendingMessage('You\'ve acquired the Blacksmith\'s Belongings!'));
        },
        CraftSwordHilt: () => {
          dispatch(addItem({ id: 'SwordHilt', quantity: 1 }));
          dispatch(addPendingMessage('Sword Hilt crafted successfully!'));
        },
        CraftUnfinishedSmallSword: () => {
          dispatch(addItem({ id: 'UnfinishedSmallSword', quantity: 1 }));
          dispatch(addPendingMessage('Unfinished Small Sword crafted successfully!'));
        },
        CraftSmallSword: () => {
          if (player.items['SmallSword'] === undefined || player.items['SmallSword'] === 0) {
            dispatch(addItem({ id: 'SmallSword', quantity: 1 }));
            dispatch(addPendingMessage('Small Sword crafted successfully!'));
          } else {
            dispatch(addPendingMessage('You already have a Small Sword. Crafting skipped.'));
          }},
      };
    
      if (actions[activity]) {
        actions[activity]();
      } else {
        dispatch(addPendingMessage(t('activities.genericCompleted', { activity })));
      }
    }
  
    setCurrentActivity(null);
    setActivityTimer(0);
    localStorage.removeItem('timerEndTime');
    localStorage.removeItem('currentActivity');
    console.log("Activity completion finished");
  };

  const startActivity = (activity, duration) => {
  
    if (currentActivity) {
      setAlertMessage(t('errors.alreadyDoingActivity'));
      return;
    }
  
    if (activity === 'Combat Training') {
      if (player.coins < 25) {
        setAlertMessage(t('errors.notEnoughCoinsForCombatTraining', { cost: 25 }));
        return;
      }
      dispatch(spendCoins(25));
    }
  
    let activityDuration = duration;
    if (activity === t('activities.work')) {
      activityDuration = player.workDuration || duration;
    }
  
    setCurrentActivity(activity);
    setActivityTimer(activityDuration);
    setActivityCompleted(false);  // Reset the activityCompleted state
    const endTime = Date.now() + activityDuration;
    localStorage.setItem('timerEndTime', endTime.toString());
    localStorage.setItem('currentActivity', activity);
  };
    
    const getCraftingWeaponImage = (level) => {
      switch (level) {
        case 2:
          return WeaponLvl2ArrowImage;
        case 3:
          return WeaponLvl3SmallSwordImage;
        case 4:
          return LegendWeaponsLvl4Image;
        default:
          return WeaponLvl2ArrowImage; // Default image, you might want to use a different one for level 1
      }
    };

    const getCraftingWeaponInfo = (level) => {
      switch (level) {
        case 2:
          return 'Craft arrows and bows to aid your adventures.';
        case 3:
          return 'Forge small swords for more powerful combat.';
        case 4:
          return 'Create legendary weapons with immense power.';
        default:
          return 'Unlock weapon crafting at higher levels.';
      }
    };


    const toggleModal = (modalName) => {
      setModalStates(prev => ({ ...prev, [modalName]: !prev[modalName] }));
    };
    const handleMainButtonClick = () => {
      if (currentActivity) {
        // Handle ongoing activity
      } else {
        // Prompt to start a new activity
      }
    };
    const activityCards = [
      { name: t('activities.work'), image: workImage, info: t('activities.workInfo'), duration: player.workDuration  },
      { name: t('activities.illumination'), image: illuminationImage, info: t('activities.illuminationInfo'), duration:  4 * 60 * 1000 }, // 4 hours
      { name: t('activities.combatTraining'), image: combatTrainingImage, info: t('activities.combatTrainingInfo'), duration: 3 * 60 * 60 * 1000 }, // 3 hours
      { name: t('activities.mapExploration'), image: mapExplorationImage, info: t('activities.mapExplorationInfo'), requiredLevel: 2 },
      { name: t('activities.craftingWeapons'), image: getCraftingWeaponImage(player.level), info: t(`activities.craftingWeaponsInfo.level${player.level}`), requiredLevel: 2 },
      { name: t('activities.merchant.title'), image: merchantImage,info: t('activities.merchant.info')   },];

      const ActivityCard = ({ name, image, info, duration, requiredLevel, isInactive, onClick, t }) => {
        const player = useSelector((state) => state.player);
        const isMerchant = name === t('merchant.title');
        const isLevelLocked = requiredLevel && player.level < requiredLevel;
        const showLevelRequirement = isLevelLocked && player.level < 3 && !isInactive;
      
        return (
          <div className={`activity-card ${isInactive ? 'inactive' : ''} ${isLevelLocked ? 'level-locked' : ''}`}>
            <div className="activity-image" style={{ backgroundImage: `url(${image})` }}></div>
            <div className="activity-content">
              <h2>{name}</h2>
              <p>{info}</p>
              {showLevelRequirement && (
                <p className="required-level">
                  {t('errors.levelRequired', { level: requiredLevel })}
                </p>
              )}
              <button 
                onClick={onClick}
                disabled={isInactive || isLevelLocked}
                className="start-button"
              >
               {isInactive 
                 ? t('activities.inProgress') 
                 : isMerchant 
                   ? t('activities.merchant.visit')
                   : `${t('activities.start')} ${name}`
               }
              </button>
            </div>
          </div>
        );
      };
    const handleCraftingClick = () => {
      if (player.level === 2) {
        toggleModal('WeaponLvl2Arrow');
      } else if (player.level === 3) {
        toggleModal('WeaponLvl3SmallSword');
      } else if (player.level === 4) {
        toggleModal('LegendWeaponsLvl4');
      } else {
        setAlertMessage(t('errors.weaponCraftingNotUnlocked'));
      }
    };
    const renderBoosterStatus = () => {
      return (
        <div className="booster-status">
          <h3>Active Boosters:</h3>
          {Object.entries(player.activeBoosts).map(([boostName, expirationTime]) => {
            if (expirationTime > Date.now()) {
              const timeLeft = Math.floor((expirationTime - Date.now()) / (1000 * 60)); // minutes left
              return (
                <p key={boostName}>{boostName}: {timeLeft} minutes remaining</p>
              );
            }
            return null;
          })}
        </div>
      );
    }
    const renderActivityCard = (card) => {
      const { name, image, info, duration, requiredLevel } = card;
      const maxSessions = player.level >= 2 ? 2 : 1;
      const isCombatTraining = name === t('activities.combatTraining');
      const isCombatTrainingLimitReached = isCombatTraining && player.combatTrainingSessionsToday >= maxSessions;
      const isInactive = (currentActivity && currentActivity !== name) || 
                         (requiredLevel && player.level < requiredLevel) ||
                         isCombatTrainingLimitReached;
      const isLevelRequirementMet = !requiredLevel || player.level >= requiredLevel;
    
      const handleButtonClick = () => {
        if (name === t('activities.mapExploration')) {
          toggleModal('mapExploration');
        } else if (name === t('activities.craftingWeapons')) {
          handleCraftingClick();
        } else if (name === t('merchant.title')) {
          if (isMerchantAvailable) {
            toggleModal('merchant');
          } else {
            const message = merchantCountdown
              ? t('merchant.nextVisitWithCountdown', {
                  date: nextMerchantVisit.toLocaleString(),
                  days: merchantCountdown.days,
                  hours: merchantCountdown.hours,
                  minutes: merchantCountdown.minutes,
                  seconds: merchantCountdown.seconds
                })
              : t('merchant.notAvailable');
            setAlertMessage(message);
          }
        } else if (isCombatTraining) {
          if (isCombatTrainingLimitReached) {
            // Do nothing, button is disabled
          } else if (player.coins < 25) {
            setAlertMessage(t('market.notEnoughCoins'));
          } else {
            dispatch(spendCoins(25));
            startActivity(name, duration);
          }
        } else {
          startActivity(name, duration);
        }
      };
      
      return (
        <ActivityCard
          key={name}
          name={name}
          image={image}
          info={info}
          duration={duration}
          requiredLevel={requiredLevel}
          isInactive={isInactive}
          onClick={handleButtonClick}
          t={t}
          buttonText={isCombatTrainingLimitReached ? t('activities.combatTrainingLimitReached') : null}
        />
      );
    };

    const handleEquipSkin = (skinId) => {
      setLocalCurrentSkin(skinId);
      dispatch(setCurrentSkin(skinId));
    };

    const getCharacterImage = () => {
      const images = {
        male: [level1Male, level2Male, level3Male, level4Male],
        female: [level1Female, level2Female, level3Female, level4Female],
      };
    
      const skinImages = {
        male: {
          PharaohWarrior,
          MummyGuardian,
          AnubisFighter,
          RasChosen,
          SphinxSentinel,
          OsirisReborn,
          ScarabKnight,
          SetsEnforcer,
        },
        female: {
          DesertNomadlvl3,
          DesertNomadlvl4,
          EmperorWarriorlvl3,
          EmperorWarriorlvl4,
          Jade,
          PharaohsGuardlvl4,
          PracticalWarriorlvl3,
          PracticalWarriorlvl4,
        },
      };
    
      const gender = player.gender || 'male';
      
      // Ensure the level is within bounds (1-4)
      const levelIndex = Math.min(Math.max(player.level - 1, 0), 3);

      // If player has equipped a skin and is level 3 or 4, use the skin image
      if (currentSkin !== 'default' && player.level >= 3) {
        return skinImages[gender][currentSkin] || images[gender][levelIndex];
      }

      // Otherwise, return the appropriate level image
      return images[gender][levelIndex];
    };




  const playBackgroundMusic = () => {
    Object.values(audioRefs.current).forEach(audio => {
      audio.pause();
      audio.currentTime = 0;
    });

    let currentTrack;
    if (player.level >= 4) {
      currentTrack = audioRefs.current.level4;
    } else if (player.level >= 3) {
      currentTrack = audioRefs.current.level3;
    } else {
      currentTrack = audioRefs.current.default;
    }

    if (!isMuted) {
      currentTrack.loop = true;
      currentTrack.play().catch(error => console.error("Audio playback failed:", error));
    }
  };

  useEffect(() => {
    if (!isLoading) {
      playBackgroundMusic();
    }
    return () => {
      Object.values(audioRefs.current).forEach(audio => {
        audio.pause();
        audio.currentTime = 0;
      });
    };
  }, [isLoading, player.level, isMuted]);

  const toggleMute = () => {
    setIsMuted(!isMuted);
    Object.values(audioRefs.current).forEach(audio => {
      audio.muted = !isMuted;
    });
  };
  if (isLoading) {
    return (
      <LoadingScreen
        progress={loadingProgress}
        backgroundImage={loadingBackground}
        topText="Loading Mythos"
        bottomText="Preparing your adventure..."
      />
    );
  }

  return (
    <div className="game-container">
      {showMiniGames && (
        <div className="mini-games-modal">
          <button onClick={() => setShowMiniGames(false)}>{t('general.close')}</button>
          <MiniGames />
        </div>
      )}

{(showRewardAlert && dailyRewardMessage) || currentMessage ? (
  <CustomAlert
    message={showRewardAlert ? dailyRewardMessage : currentMessage}
    onClose={showRewardAlert ? handleCloseAlert : handleMessageClose}
  />
) : null}

    {showSkillSelection && (
  <Modal isOpen={showSkillSelection} onClose={() => setShowSkillSelection(false)} title={t('skills.chooseNewSkill')}>
    <SkillSelection onClose={() => setShowSkillSelection(false)} />
  </Modal>
)}

      <div className="top-section">
        <div className="main-buttons">
          <button onClick={() => toggleModal('wallet')} className="icon-button">
            <img src={walletIcon} alt={t('market.title')} />
          </button>
          <button onClick={() => setShowProfile(!showProfile)} className="profile-button">
            {t('mainGameScreen.level')} {player.level}
          </button>
          <button onClick={() => toggleModal('settings')} className="icon-button">
            <img src={settingsIcon} alt={t('settings.title')} />
          </button>
        </div>
        <div className="player-stats">
          <div className="stat-item">
            <img src={coinIcon} alt={t('mainGameScreen.coins')} />
            <span>{player.coins}</span>
          </div>
          <div className="stat-item">
            <img src={experienceIcon} alt={t('mainGameScreen.experience')} />
            <span>{player.experience}</span>
          </div>
          <div className="stat-item">
            <img src={powerIcon} alt={t('mainGameScreen.power')} />
            <span>{player.power}</span>
          </div>
          <div className="stat-item">
            <img src={knowledgeIcon} alt={t('mainGameScreen.knowledge')} />
            <span>{player.knowledge}</span>
          </div>
        </div>
      </div>

      <div className="tabs">
        <button className={`tab-button ${activeTab === null ? 'active' : ''}`} onClick={handleMainTabClick}>
          <img src={mainIcon} alt={t('general.main')} />
          {t('general.main')}
        </button>
        <button className={`tab-button ${activeTab === 'market' ? 'active' : ''}`} onClick={() => handleTabClick('market')}>
          <img src={marketIcon} alt={t('market.header')} />
          {t('market.header')}
        </button>
        <button className={`tab-button ${activeTab === 'referral' ? 'active' : ''}`} onClick={() => handleTabClick('referral')}>
          <img src={referralIcon} alt={t('social.referral.title')} />
          {t('social.referral.title')}
        </button>
        <button className={`tab-button ${activeTab === 'socialTasks' ? 'active' : ''}`} onClick={() => handleTabClick('socialTasks')}>
          <img src={socialTasksIcon} alt={t('social.tasks.title')} />
          {t('social.tasks.title')}
        </button>
        <button className={`tab-button ${activeTab === 'achievements' ? 'active' : ''}`} onClick={() => handleTabClick('achievements')}>
          <img src={achievementsIcon} alt={t('achievements.title')} />
          {t('achievements.title')}
          {hasNewAchievements && <span className="notification-dot"></span>}
        </button>
      </div>  
      
      {showProfile && (
        <div className="profile-overlay">
          <div className="profile-card">
            <button className="close-button" onClick={() => setShowProfile(false)}>✖</button>
            <h2>{t('characterCreation.title')}</h2>
            <div className="character-section">
              <img src={getCharacterImage()} alt="Character" className="character-image" />
            <h3 className={`player-name ${getGlowClass()}`}>
                {player.name ? capitalizeFirstLetter(player.name) : 'Unnamed Hero'}
              </h3>
            
            </div>
            <button className="inventory-button" onClick={() => toggleModal('inventory')}>{t('inventory.title')}</button>
            {player.pendingSkillSelection && (
              <button className="skill-button" onClick={() => setShowSkillSelection(true)}>{t('characterCreation.title')}</button>
            )}
          </div>
        </div>
      )}
      
      {activeTab === null && (
        <>
          <div>
            <TimerDisplay timeRemaining={activityTimer} />
          </div>
          <div className="activity-cards">
            {activityCards.map(renderActivityCard)}
          </div>
        </>
      )}

      <div className="bottom-section">
      {activeTab === 'market' && (
  <Market 
    setAlertMessage={(msg) => {
      console.log("Message from Market:", msg);
      setAlertMessage(msg);
    }} 
  />
)}
        {activeTab === 'referral' && <ReferralSystem setAlertMessage={setAlertMessage} />}
        {activeTab === 'socialTasks' && <SocialTasks setAlertMessage={setAlertMessage} />}
        {activeTab === 'achievements' && <Achievements setAlertMessage={setAlertMessage} />}
      </div>

      <Modal isOpen={modalStates.mapExploration} onClose={() => toggleModal('mapExploration')} title={t('mapExploration.title')}>
        <MapExploration 
          onExploreStart={handleExplorationStart}
          onClose={() => toggleModal('mapExploration')}
          setAlertMessage={setAlertMessage}
        />
      </Modal>

      <Modal isOpen={modalStates.WeaponLvl2Arrow} onClose={() => toggleModal('WeaponLvl2Arrow')} title={t('weaponCrafting.level2Title')}>
        <WeaponLvl2Arrow 
          onStartActivity={(activity, duration) => {
            startActivity(activity, duration);
            toggleModal('WeaponLvl2Arrow');
          }} 
          onClose={() => toggleModal('WeaponLvl2Arrow')}
          setAlertMessage={setAlertMessage}
        />
      </Modal>

      <Modal isOpen={modalStates.WeaponLvl3SmallSword} onClose={() => toggleModal('WeaponLvl3SmallSword')} title={t('weaponCrafting.level3Title')}>
        <WeaponLvl3SmallSword 
          onStartActivity={(activity, duration) => {
            startActivity(activity, duration);
            toggleModal('WeaponLvl3SmallSword');
          }} 
          onClose={() => toggleModal('WeaponLvl3SmallSword')}
          setAlertMessage={setAlertMessage}
        />
      </Modal>

      <Modal isOpen={modalStates.LegendWeaponsLvl4} onClose={() => toggleModal('LegendWeaponsLvl4')} title={t('weaponCrafting.level4Title')}>
        <LegendWeaponsLvl4 
          onStartActivity={(activity, duration) => {
            startActivity(activity, duration);
            toggleModal('LegendWeaponsLvl4');
          }} 
          onClose={() => toggleModal('LegendWeaponsLvl4')} 
        />
      </Modal>
   
      <Modal
        isOpen={modalStates.merchant}
        onClose={() => toggleModal('merchant')}
        title={t('merchant.title')}
      >
        <Merchant 
          onClose={() => toggleModal('merchant')} 
          isAvailable={isMerchantAvailable} 
          setAlertMessage={setAlertMessage}
        />
      </Modal>
      
      {alertMessage && (
        <CustomAlert
          message={alertMessage}
          onClose={() => setAlertMessage(null)}
        />
      )}

      <InventoryModal 
        isOpen={modalStates.inventory} 
        onClose={() => toggleModal('inventory')} 
        onSkinChange={handleEquipSkin}
      />

      <Modal
        isOpen={modalStates.settings}
        onClose={() => toggleModal('settings')}
      >
        <SettingsModal isMuted={isMuted} toggleMute={toggleMute} dispatch={dispatch}
      setActivityTimer={setActivityTimer}
      setCurrentActivity={setCurrentActivity} />
        
      </Modal>

      <Modal
        isOpen={modalStates.wallet}
        onClose={() => toggleModal('wallet')}
      >
        <WalletModal />
      </Modal>
    </div>
  );
};

export default MainGameScreen;