import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18n from './i18n';
import StartScreen from './components/StartScreen';
import CharacterCreation from './components/CharacterCreation';
import MainGameScreen from './components/MainGameScreen';
import LoadingScreen from './components/LoadingScreen';
import PCMessage from './components/PCMessage';
import { loadPersistedState } from './redux/playerSlice';
import WebApp from '@twa-dev/sdk';

const App = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(true);
  const player = useSelector(state => state.player);
  const dispatch = useDispatch();
  const { i18n } = useTranslation();

 
  

  useEffect(() => {
    const checkDevice = () => {
      const mobile = /Mobi|Android/i.test(navigator.userAgent) || WebApp.platform !== 'web_app';
      setIsMobile(mobile);
    };

    checkDevice();

    // Load the persisted state
    const persistedState = localStorage.getItem('gameState');
    if (persistedState) {
      const parsedState = JSON.parse(persistedState);
      dispatch(loadPersistedState(parsedState));
      
      // Set the language from the persisted state
      if (parsedState.language) {
        i18n.changeLanguage(parsedState.language);
      }
    }
    setIsLoading(false);
  }, [dispatch, i18n]);

  useEffect(() => {
    // Set RTL for Arabic and Farsi
    const isRTL = ['ar', 'fa'].includes(i18n.language);
    document.documentElement.dir = isRTL ? 'rtl' : 'ltr';
    document.documentElement.lang = i18n.language;
  }, [i18n.language]);

  if (isLoading) {
    return <LoadingScreen />; 
  }

  if (!isMobile) {
    return <PCMessage />;
  }

  return (
    <I18nextProvider i18n={i18n}>
      <Router>
        <Routes>
          <Route 
            path="/" 
            element={player.name ? <Navigate to="/game" /> : <StartScreen />} 
          />
          <Route 
            path="/character-creation" 
            element={player.name ? <Navigate to="/game" /> : <CharacterCreation />} 
          />
          <Route 
            path="/game" 
            element={player.name ? <MainGameScreen /> : <Navigate to="/" />} 
          />
        </Routes>
      </Router>
    </I18nextProvider>
  );
};

export default App;