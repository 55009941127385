import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './LoadingScreen.css';

const LoadingScreen = ({ backgroundImage, topText, bottomText, onLoadingComplete }) => {
  const symbols = ['☥', '𓂀', '𓅓', '𓃒', '𓆣', '▲', '△', '◭'];
  const [dancingSymbols, setDancingSymbols] = useState([]);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    console.log('LoadingScreen mounted');  // Debug log

    const symbolIntervalId = setInterval(() => {
      const newSymbols = [];
      while (newSymbols.length < 3) {
        const randomSymbol = symbols[Math.floor(Math.random() * symbols.length)];
        if (!newSymbols.includes(randomSymbol)) {
          newSymbols.push(randomSymbol);
        }
      }
      setDancingSymbols(newSymbols);
    }, 1000);

    // Show loading screen for exactly 5 seconds
    const loadingTimeout = setTimeout(() => {
      console.log('5 seconds passed, hiding LoadingScreen');  // Debug log
      setIsVisible(false);
      if (onLoadingComplete) {
        onLoadingComplete();
      }
    }, 5000);

    return () => {
      console.log('Cleaning up LoadingScreen');  // Debug log
      clearInterval(symbolIntervalId);
      clearTimeout(loadingTimeout);
    };
  }, []); // Remove onLoadingComplete from dependencies

  useEffect(() => {
    console.log('isVisible:', isVisible);  // Debug log
  }, [isVisible]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="loading-screen" style={{ backgroundImage: `url(${backgroundImage})` }}>
      <div className="loading-content">
        <div className="dancing-symbols">
          {dancingSymbols.map((symbol, index) => (
            <span key={index} className="dancing-symbol">{symbol}</span>
          ))}
        </div>
        <h1 className="loading-title">{topText}</h1>
        <p className="loading-text">{bottomText}</p>
      </div>
    </div>
  );
};

LoadingScreen.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
  topText: PropTypes.string.isRequired,
  bottomText: PropTypes.string.isRequired,
  onLoadingComplete: PropTypes.func,
};

export default LoadingScreen;