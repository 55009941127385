import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { addCoins, setCompletedTasks } from '../redux/playerSlice';
import { Button, Panel } from './UIComponents';
import { FaTelegram, FaTwitter, FaYoutube, FaCheckCircle, FaHourglassHalf, FaExternalLinkAlt } from 'react-icons/fa';
import './SocialTasks.css';

const SocialTasks = ({ setAlertMessage }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const playerCoins = useSelector(state => state.player.coins) || 0;
  const completedTasks = useSelector(state => state.player.completedTasks) || [];

  const TASK_CONFIG = {
    followTelegram: { icon: <FaTelegram />, reward: 300, link: 'https://t.me/GameMythos', description: t('social.tasks.followTelegramDesc'), revisitable: true },
    boostTelegram: { icon: <FaTelegram />, reward: 300, link: 'https://t.me/boost/GameMythos', description: t('social.tasks.boostTelegramDesc'), revisitable: false },
    followTwitter: { icon: <FaTwitter />, reward: 300, link: 'https://x.com/Game_Mythos', description: t('social.tasks.followTwitterDesc'), revisitable: true },
    subscribeYoutube: { icon: <FaYoutube />, reward: 300, link: 'https://www.youtube.com/@GameMythos', description: t('social.tasks.subscribeYoutubeDesc'), revisitable: true },
    watchTutorial: { icon: <FaYoutube />, reward: 500, link: 'https://youtu.be/kZGUdrV8SUs', description: t('social.tasks.watchTutorialDesc'), revisitable: true },
  };

  const [tasks, setTasks] = useState(() => 
    Object.keys(TASK_CONFIG).reduce((acc, task) => {
      acc[task] = { completed: completedTasks.includes(task), timer: null };
      return acc;
    }, {})
  );

  useEffect(() => {
    const savedTasks = JSON.parse(localStorage.getItem('completedTasks') || '[]');
    dispatch(setCompletedTasks(savedTasks));
  }, [dispatch]);

  useEffect(() => {
    setTasks(prevTasks => 
      Object.keys(prevTasks).reduce((acc, task) => {
        acc[task] = { ...prevTasks[task], completed: completedTasks.includes(task) };
        return acc;
      }, {})
    );
  }, [completedTasks]);

  const handleTaskStart = useCallback((task) => {
    if (tasks[task].completed && !TASK_CONFIG[task].revisitable) {
      setAlertMessage(t('social.tasks.alreadyCompleted'));
      return;
    }

    try {
      window.open(TASK_CONFIG[task].link, '_blank');
      
      if (!tasks[task].completed) {
        setTasks(prevTasks => ({
          ...prevTasks,
          [task]: { ...prevTasks[task], timer: setTimeout(() => completeTask(task), 20000) }
        }));
        setAlertMessage(t('social.tasks.taskStarted'));
      } else {
        setAlertMessage(t('social.tasks.taskRevisited'));
      }
    } catch (error) {
      console.error('Error starting task:', error);
      setAlertMessage(t('social.tasks.startError'));
    }
  }, [tasks, setAlertMessage, t]);

  const completeTask = useCallback((task) => {
    if (!tasks[task].completed) {
      setTasks(prevTasks => ({
        ...prevTasks,
        [task]: { ...prevTasks[task], completed: true, timer: null }
      }));

      dispatch(addCoins(TASK_CONFIG[task].reward));
      
      const updatedCompletedTasks = [...completedTasks, task];
      dispatch(setCompletedTasks(updatedCompletedTasks));
      localStorage.setItem('completedTasks', JSON.stringify(updatedCompletedTasks));

      setAlertMessage(t('social.tasks.taskCompleted', { 
        reward: TASK_CONFIG[task].reward, 
        task: t(`social.tasks.${task}`) 
      }));
    }
  }, [dispatch, setAlertMessage, completedTasks, tasks, t]);

  useEffect(() => {
    return () => {
      Object.values(tasks).forEach(task => {
        if (task.timer) clearTimeout(task.timer);
      });
    };
  }, [tasks]);

  return (
    <div className="social-tasks">
      <h2 className="social-tasks-header">{t('social.tasks.header')}</h2>
      {Object.entries(TASK_CONFIG).map(([taskName, taskData]) => (
        <div key={taskName} className="task-item">
          <div className="task-title">
            <span className="task-icon">{taskData.icon}</span>
            {t(`social.tasks.${taskName}`)}
          </div>
          <div className="task-info">
            <div className="task-details">
              <p>{taskData.description}</p>
              <p className="task-reward">
                {tasks[taskName].completed 
                  ? t('social.tasks.alreadyRewarded') 
                  : t('social.tasks.reward', { amount: taskData.reward })}
              </p>
            </div>
            <Button
              onClick={() => handleTaskStart(taskName)}
              className={`task-button ${tasks[taskName].completed ? 'completed' : ''}`}
            >
              {tasks[taskName].completed ? (
                <>
                  {taskData.revisitable ? t('social.tasks.revisit') : t('social.tasks.completed')} 
                  {taskData.revisitable ? <FaExternalLinkAlt /> : <FaCheckCircle />}
                </>
              ) : tasks[taskName].timer ? (
                <>
                  {t('social.tasks.inProgress')} <FaHourglassHalf />
                </>
              ) : (
                t('social.tasks.startTask')
              )}
            </Button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default SocialTasks;