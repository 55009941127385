import React from 'react';
import { useTranslation } from 'react-i18next';
import { resetGame } from '../redux/playerSlice';
import './SettingsModal.css';

import muteIcon from '../images/icons/mute.png';
import unmuteIcon from '../images/icons/unmute.png';
import languageIcon from '../images/icons/language.png';
import deleteIcon from '../images/icons/delete.png';

const SettingsModal = ({ isMuted, toggleMute, dispatch, setActivityTimer, setCurrentActivity }) => {
  const { t, i18n } = useTranslation();

  const languages = [
    { code: 'en', name: 'English' },
    { code: 'es', name: 'Español' },
    { code: 'ar', name: 'العربية' },
    { code: 'de', name: 'Deutsch' },
    { code: 'fa', name: 'فارسی' },
    { code: 'fr', name: 'Français' },
    { code: 'tr', name: 'Türkçe' },
    { code: 'ru', name: 'Русский' },
    { code: 'id', name: 'Bahasa Indonesia' }
  ];

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(lang);
  };

  const handleResetGame = () => {
    if (window.confirm(t('settings.resetConfirm'))) {
      dispatch(resetGame());
      setActivityTimer(0);
      setCurrentActivity(null);
      localStorage.removeItem('timerEndTime');
      localStorage.removeItem('currentActivity');
      localStorage.removeItem('gameState');
      window.location.reload(); // Reload the page to ensure a complete reset
    }
  };

  return (
    <div className="settings-container">
      <h3>{t('settings.title')}</h3>
      <div className="settings-options">
        <button className="setting-button" onClick={toggleMute}>
          <img src={isMuted ? unmuteIcon : muteIcon} alt={isMuted ? t('settings.unmute') : t('settings.mute')} />
          <span>{isMuted ? t('settings.unmute') : t('settings.mute')}</span>
        </button>
        
        <div className="language-grid">
          {languages.map((lang) => (
            <button 
              key={lang.code}
              className={`language-button ${i18n.language === lang.code ? 'active' : ''}`} 
              onClick={() => handleLanguageChange(lang.code)}
            >
              <img src={languageIcon} alt={lang.name} />
              <span>{lang.name}</span>
            </button>
          ))}
        </div>
        <button className="setting-button" onClick={handleResetGame}>
          <img src={deleteIcon} alt={t('settings.resetGame')} />
          <span>{t('settings.resetGame')}</span>
        </button>
      </div>
    </div>
  );
};

export default SettingsModal;