import { t } from 'i18next';

export const getExplorationStory = (region, rewards, specialScenarios = {}) => {
  console.log("getExplorationStory called with:", { region, rewards, specialScenarios });
  const { coins, items } = rewards;
  const itemsList = items.map(item => `${item.quantity} ${item.id}`).join(', ');
  console.log("Items list:", itemsList);

  const stories = {
    Mountain: [
      t('exploration.mountain.story1', { coins, items: itemsList }),
      t('exploration.mountain.story2', { coins, items: itemsList }),
      t('exploration.mountain.story3', { coins, items: itemsList })
    ],
    'Secret Tombs': [
      t('exploration.secretTombs.story1', { coins, items: itemsList }),
      t('exploration.secretTombs.story2', { coins, items: itemsList }),
      t('exploration.secretTombs.story3', { coins, items: itemsList })
    ],
    'River Valley': [
      t('exploration.riverValley.story1', { coins, items: itemsList }),
      t('exploration.riverValley.story2', { coins, items: itemsList }),
      t('exploration.riverValley.story3', { coins, items: itemsList })
    ],
    Quarry: [
      t('exploration.quarry.story1', { coins, items: itemsList }),
      t('exploration.quarry.story2', { coins, items: itemsList }),
      t('exploration.quarry.story3', { coins, items: itemsList })
    ],
    Desert: [
      t('exploration.desert.story1', { coins, items: itemsList }),
      t('exploration.desert.story2', { coins, items: itemsList }),
      t('exploration.desert.story3', { coins, items: itemsList })
    ],
    'Royal Tournament Grounds': [
      t('exploration.royalTournament.story1', { coins, items: itemsList }),
      t('exploration.royalTournament.story2', { coins, items: itemsList }),
      t('exploration.royalTournament.story3', { coins, items: itemsList })
    ]
  };
  console.log("Available stories for region:", stories[region]);

  const specialStories = {
    rareItem: t('exploration.special.rareItem', { item: specialScenarios.rareItem, coins, items: itemsList }),
    hunting: t('exploration.special.hunting', { coins, items: itemsList }),
    blacksmithItems: [
      t('exploration.special.blacksmith1', { coins, items: itemsList }),
      t('exploration.special.blacksmith2', { coins, items: itemsList }),
      t('exploration.special.blacksmith3', { coins, items: itemsList })
    ]
  };
  if (!stories[region]) {
    console.error(`No stories found for region: ${region}`);
    return t('exploration.error');
  }
  
  let baseStory = stories[region] ? stories[region][Math.floor(Math.random() * stories[region].length)] : null;
  console.log("Selected base story:", baseStory);

  if (specialScenarios.rareItem) {
    baseStory += ' ' + specialStories.rareItem;
    console.log("Added rare item story");
  }
  if (specialScenarios.hunting) {
    baseStory += ' ' + specialStories.hunting;
    console.log("Added hunting story");
  }
  if (specialScenarios.blacksmithItems) {
    baseStory = specialStories.blacksmithItems[Math.floor(Math.random() * specialStories.blacksmithItems.length)];
    console.log("Used blacksmith items story");
  }

  const finalStory = baseStory || t('exploration.default', { region, coins, items: itemsList });
  console.log("Final story:", finalStory);
  return finalStory;
};