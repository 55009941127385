import { createSlice } from '@reduxjs/toolkit';
import { boostService } from '../services/BoostService';
import { useTranslation } from 'react-i18next';
import WebApp from '@twa-dev/sdk';
import i18next from 'i18next';
const telegramId = WebApp.initDataUnsafe?.user?.id || 0;
const referralCode = telegramId + 13252081519;

function getDaySuffix(day) {
  if (day >= 11 && day <= 13) {
    return 'th';
  }
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

const initialState = {
  coins: 0,
  level: 1,
  experience: 0,
  workBonus: 0,
  lastLoginTimestamp: Date.now(),
  pendingSkillSelection: false,
  selectedSkills: [],
  pendingMessages: [],
  power: 0,
  knowledge: 0, 
  shopDiscount: 0,
  coinMultiplier: 1,
  referralRewardBonus: 0,
  referralCount: 0,
  workDuration: 8 * 60 * 60 * 1000, 
  isMuted: false, // Keep this if you still want to use it elsewhere
  volume: 1, // Add this new state for volume control
  lastDailyRewardClaim: null,
  consecutiveDays: 0,
  dailyRewardMessage: '',
  selectedLegendaryWeapon: null,
  gender: '',
  completedTasks: [],
  telegramId: telegramId,
  referralCode: referralCode,
  completedAchievements: [],
  combatTrainingSessionsToday: 0,
  lastCombatTrainingDay: null,
  language: 'en',
  name: '',
  items: {
    UnfinishedSmallSword: 0,
    SwordHilt: 0,
    BlacksmithBelongings: 0,
    BlacksmithTrust: 0,
    BowFrame: 0,
    Bow: 0,
    Arrow: 0,
    SmallSword: 0,
    Wood: 0,
    Iron: 0,
    Silver: 0,
    PapyrusScrollsOfPower: 0,
    DesertSandCrystal: 0,
    MummysLinen: 0,
    SphinxsRiddleStone: 0,
    AnubisFang: 0,
    RasSunstone: 0,
    NileLotusEssence: 0,
    PharaohsGold: 0,
    NileCrocodileScales: 0,
    ScarabBeetleEssence: 0,
    WorkSpeedBooster: 0,
    TrainingEffectivenessBooster: 0,
    CombatTrainingBooster: 0,

  },
  
  currentSkin: 'default',
  ownedSkins: ['default'],
  addSkin:[''],
  combatTrainingHours: 0,
  activeBoosts: {},
  totalScore: 0,
  legendaryWeapons: [],
};

export const playerSlice = createSlice({
  name: 'player',
  initialState,
  reducers: {
    addCoins: (state, action) => {
      let amount = action.payload;
      if (boostService.isBoostActive('Work Speed Booster')) {
        amount *= 1.5; // 50% increase
      }
      state.coins += amount;
       
    },
    extraReducers: (builder) => {
      builder.addCase(resetGame, (state) => {
        Object.assign(state, initialState);
      });
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    spendCoins: (state, action) => {
      state.coins -= action.payload;
    },
    addExperience: (state, action) => {
      state.experience += action.payload;
    },
    increaseWorkBonus: (state, action) => {
      state.workBonus += action.payload;
    },
    updateLastLogin: (state) => {
      state.lastLoginTimestamp = Date.now();
       
    },
    setSelectedLegendaryWeapon: (state, action) => {
      state.selectedLegendaryWeapon = action.payload;
    },
    addIdleCoins: (state, action) => {
      state.coins += action.payload;
    },
    claimDailyReward: (state, action) => {
      state.coins += action.payload;
      state.lastDailyRewardClaim = Date.now();
      state.consecutiveDays += 1;
    },
    addPendingMessage: (state, action) => {
      state.pendingMessages = state.pendingMessages || [];
      state.pendingMessages.push(action.payload);
      // Save to localStorage
      localStorage.setItem('pendingMessages', JSON.stringify(state.pendingMessages));
    },
    removePendingMessage: (state, action) => {
      if (state.pendingMessages && state.pendingMessages.length > 0) {
        state.pendingMessages.splice(action.payload, 1);
        // Save to localStorage
        localStorage.setItem('pendingMessages', JSON.stringify(state.pendingMessages));
      }
    },
    setPendingMessages: (state, action) => {
      state.pendingMessages = action.payload || [];
    },
  
    resetConsecutiveDays: (state) => {
      state.consecutiveDays = 0;
    },
    completeAchievement: (state, action) => {
      if (!state.completedAchievements.includes(action.payload)) {
        state.completedAchievements.push(action.payload);
      }
    },
    setCompletedTasks: (state, action) => {
      state.completedTasks = action.payload;
    },
    checkAndAwardDailyReward: (state) => {
      const now = new Date();
      const lastClaim = state.lastDailyRewardClaim ? new Date(state.lastDailyRewardClaim) : null;

      if (!lastClaim || now.getDate() !== lastClaim.getDate() || now.getMonth() !== lastClaim.getMonth() || now.getFullYear() !== lastClaim.getFullYear()) {
        if (lastClaim && (now - lastClaim) / (1000 * 60 * 60 * 24) > 1) {
          // Reset if more than a day has passed
          state.consecutiveDays = 1;
        } else {
          state.consecutiveDays += 1;
        }

        if (state.consecutiveDays > 30) {
          state.consecutiveDays = 1; // Reset after 30 days
        }

        const reward = state.consecutiveDays * 10;
        state.coins += reward;
        state.lastDailyRewardClaim = now.toISOString();

        state.dailyRewardMessage = i18next.t('dailyReward.welcomeMessage', {
          name: state.name,
          day: state.consecutiveDays,
          suffix: getDaySuffix(state.consecutiveDays),
          reward: reward
        });
      } else {
        state.dailyRewardMessage = ''; // No message if already claimed
      }
    },

    clearDailyRewardMessage: (state) => {
      state.dailyRewardMessage = '';
    },  

    setMuted: (state, action) => {
      state.isMuted = action.payload;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    addItem: (state, action) => {
      const { id, quantity } = action.payload;
      if (state.items[id] === undefined) {
        state.items[id] = quantity;
      } else {
        state.items[id] += quantity;
      }
    },
    spendItem: (state, action) => {
      const { id, quantity } = action.payload;
      if (state.items[id] >= quantity) {
        state.items[id] -= quantity;
      } else {
        console.warn(`Not enough ${id} to spend.`);
      }
    },


    loadPersistedState: (state, action) => {
      return { ...state, ...action.payload };
    },
    addPower: (state, action) => {
      let amount = action.payload;
      if (boostService.isBoostActive('Combat Training Booster')) {
        amount *= 1.75; // 75% increase
      }
      state.power += amount;
       
    },
    updateCharacter: (state, action) => {
      state.gender = action.payload.gender;
      state.name = action.payload.name;
    },
    addLegendaryWeapon: (state, action) => {
      const { weapon } = action.payload;
      if (!state.legendaryWeapons) {
        state.legendaryWeapons = [];
      }
      state.legendaryWeapons.push(weapon);
    },
    addKnowledge: (state, action) => {
      let amount = action.payload;
      if (boostService.isBoostActive('Research Effectiveness Booster')) {
        amount *= 2; // Double effectiveness
      }
      state.knowledge += amount;
       
    },
    sellLegendaryWeapon: (state, action) => {
      state.coins += action.payload;
      state.selectedLegendaryWeapon = null;
    },
    activateBoost: (state, action) => {
      const { boostName, duration } = action.payload;
      state.activeBoosts[boostName] = Date.now() + duration * 60 * 60 * 1000; // Convert hours to milliseconds
    },
    levelUp: (state) => {
      const today = new Date().toDateString();
      
      if (state.level === 1 && state.power >= 50 && state.experience >= 25 && state.referralCount >= 2) {
        state.level = 2;
        state.pendingSkillSelection = true;
      } else if (state.level === 2 && state.power >= 150 && state.experience >= 75 && state.referralCount >= 5 && state.items['Bow'] > 0) {
        state.level = 3;
        state.pendingSkillSelection = true;
      } else if (state.level === 3 && state.power >= 300 && state.experience >= 150 && state.referralCount >= 10 && state.items['SmallSword'] > 0) {
        state.level = 4;
        state.pendingSkillSelection = true;
      }

      // Reset combat training sessions each day
      if (state.lastCombatTrainingDay !== today) {
        state.combatTrainingSessionsToday = 0;
        state.lastCombatTrainingDay = today;
      }
    },
    
    incrementCombatTrainingSessions: (state) => {
      const today = new Date().toDateString();
      if (state.lastCombatTrainingDay !== today) {
        state.combatTrainingSessionsToday = 0;
        state.lastCombatTrainingDay = today;
      }
      state.combatTrainingSessionsToday += 1;
    },
    
    setCurrentSkin: (state, action) => {
      state.currentSkin = action.payload;
    },
  
    addOwnedSkin: (state, action) => {
      if (!state.ownedSkins.includes(action.payload)) {
        state.ownedSkins.push(action.payload);
      }
    },
    addSkin: (state, action) => {
      const skinId = action.payload;
      // Ensure ownedSkins is defined and is an array
      if (!state.ownedSkins || !Array.isArray(state.ownedSkins)) {
        state.ownedSkins = []; // Initialize if undefined
      }
      if (!state.ownedSkins.includes(skinId)) {
        state.ownedSkins.push(skinId);
      }
    },
    
    selectSkill: (state, action) => {
      const skill = action.payload;
      state.selectedSkills.push(skill);
      state.pendingSkillSelection = false;

      // Apply skill effects
      switch (skill.name) {
        case 'Bargain Hunter':
          state.shopDiscount = 0.1;
          break;
        case 'Efficient Extractor':
          state.coinMultiplier *= 1.08;
          state.workDuration = 7 * 60 * 60 * 1000; // 7 hours
          break;
        case 'Network Initiator':
          state.referralRewardBonus = 0.15;
          break;
        case 'Market Savant':
          state.shopDiscount = 0.2;
          break;
        case 'Resource Specialist':
          state.coinMultiplier *= 1.12;
          state.workDuration = 6.5 * 60 * 60 * 1000; // 6.5 hours
          break;
        case 'Community Organizer':
          state.referralRewardBonus = 0.25;
          break;
        case 'Economic Mastermind':
          state.shopDiscount = 0.35;
          break;
        case 'Extraction Virtuoso':
          state.coinMultiplier *= 1.2;
          state.workDuration = 6 * 60 * 60 * 1000; // 6 hours
          break;
        case 'Recruitment Tycoon':
          state.referralRewardBonus = 0.5;
          break;
      }
    },
    updateTotalScore: (state, action) => {
      state.totalScore = Number(action.payload) || 0;
    },
    updateReferralCount: (state, action) => {
      state.referralCount = action.payload;
    },

    resetGame: () => initialState,
  },
});

export const {
  addCoins,
  spendCoins,
  addExperience,
  increaseWorkBonus,
  updateLastLogin,
  addIdleCoins,
  setSelectedLegendaryWeapon,
  sellLegendaryWeapon,
  selectSkill,
  claimDailyReward,
  resetConsecutiveDays,
  completeAchievement,
  checkAndAwardDailyReward,
  clearDailyRewardMessage, 
  addItem,
  spendItem,
  addCombatTrainingHours,
  addLegendaryWeapon,
  addPower,
  updateCharacter,
  addKnowledge,
  setCompletedTasks ,
  activateBoost,
  levelUp,
  resetGame,
  setMuted,
  addPendingMessage,
  incrementCombatTrainingSessions,
  updateReferralCount,
  removePendingMessage,
  setPendingMessages,
  setLanguage,
  setCurrentSkin,
  addSkin ,
  addOwnedSkin, 
  setVolume,
  loadPersistedState,
  updateTotalScore,
} = playerSlice.actions;

export default playerSlice.reducer;